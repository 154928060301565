import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';

import {dataManager} from "../managers/DataManager";
import {flowManager} from "../managers/FlowManager";
import {userManager} from "../managers/UserManager";
import {StepHeader} from "../components/StepHeader";
import {StepCategory} from "../components/StepCategory";
import {TileCheckbox} from "../components/TileCheckbox";
import {SubCategoryTile} from "../components/SubCategoryTile";
import {dataKeys} from "../enums/dataKeys";

ProCategoryStep.propTypes = {
  index: PropTypes.number
}

export function ProCategoryStep(props) {
  const name = ProCategoryStep.name;
  const data = dataManager.store(state => state.data); // Data is updated on state change.
  const steps = flowManager.store(state => state.steps); // Steps is updated on state change.
  const stepRef = useRef(null);
  const updateAvailableRef = useRef(null);
  const underVoltagePercentageRef = useRef(null);
  const isMountedRef = useRef(true);
  const [checkValue, setCheckValue] = useState("");
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const homey = userManager.getSelectedHomey();
  const options = {};

  // On mount, focus the step and scroll into view.
  useEffect(() => {
    stepRef.current.focus({ preventScroll: true });
    window.scrollTo({
      top: (stepRef.current.offsetTop - 120),
      behavior: "smooth"
    });
  }, [])

  useEffect(() => {
    const entry = data.find(item => item.name === name);
    if (entry) {
      setCheckValue(entry.value);
    }
  }, [data, name])

  useEffect(() => {
    if (!homey) return;
    isMountedRef.current = true;

    // On mount, check if the selected Homey Pro is up-to-date
    function getUpdateAvailable() {
      homey.authenticate()
          .then((homeyApi) => {
            return homeyApi.updates.getUpdates();
          })
          .then((updates) => {
            if (!isMountedRef.current) return
            if (Array.isArray(updates)) {
              setUpdateAvailable(updates.length > 0);
            } else {
              console.error("Updates is not an array: ", updates);
            }
          })
          .catch((error) => {
            console.error("Couldn't get updates:", error.message);
          });
    }

    // On mount, if a Homey Pro (Early 2023) is selected, get the under voltage ratio from system info.
    function getUnderVoltagePercentage() {
      if (homey.model !== "homey5q") return;
      const entry = { name }

      homey.authenticate()
          .then((homeyApi) => {
            const systemInfoPromise = homeyApi.system.getInfo();
            dataManager.pendingPromises.systemInfoPromise = systemInfoPromise;
            return systemInfoPromise;
          })
          .then((systemInfo) => {
            if (typeof systemInfo.videoCoreUnderVoltageCurrentlyRatio === "number") {
              entry[dataKeys.underVoltagePercentage] = `${systemInfo.videoCoreUnderVoltageCurrentlyRatio * 100}%`;
            } else {
              entry[dataKeys.underVoltagePercentage] = systemInfo.videoCoreUnderVoltageCurrentlyRatio;
            }
          })
          .catch((error) => {
            console.error("Couldn't get under voltage ratio:", error);
            entry[dataKeys.underVoltagePercentage] = "Couldn't get under voltage ratio";
          })
          .finally(() => {
            // If the step is changed while getting the system info, don't update data.
            if (isMountedRef.current) {
              dataManager.updateData(props.index, entry);
            }
            delete dataManager.pendingPromises.systemInfoPromise;
          });
    }

    if (!updateAvailableRef.current) {
      updateAvailableRef.current = true;
      getUpdateAvailable();
    }

    if (!underVoltagePercentageRef.current) {
      underVoltagePercentageRef.current = true;
      getUnderVoltagePercentage();
    }

    return () => {
      isMountedRef.current = false;
    }
  }, [homey, name, props.index])

  function handleSelect(e) {
    const value = e.target.value;
    const data = options[value].data;
    const action = data.action;
    const entry = { name, value, ...data };

    dataManager.updateData(props.index, entry); // Update the dataManager as well, otherwise first click will not mark subStep input as checked.
    flowManager.nextActionStep(props.index, entry, action);
  }

  // Apps & Devices
  options[`appsAndDevices-0`] = {
    title: "Apps",
    icon: "apps",
    data: { ...ProCategoryStep.apps }
  };
  options[`appsAndDevices-1`] = {
    title: "Devices",
    icon: "devices",
    data: { ...ProCategoryStep.devices }
  };

  // Automation
  options[`automation-0`] = {
    title: "Flow",
    icon: "flow",
    data: { ...ProCategoryStep.flow }
  };
  options[`automation-1`] = {
    title: "Advanced Flow",
    icon: "advanced-flow",
    data: { ...ProCategoryStep.advancedFlow }
  };
  options[`automation-2`] = {
    title: "Logic",
    icon: "logic",
    data: { ...ProCategoryStep.logic }
  };

  // Monitoring
  options[`monitoring-0`] = {
    title: "Insights",
    icon: "insights",
    data: { ...ProCategoryStep.insights }
  };
  options[`monitoring-1`] = {
    title: "Energy",
    icon: "energy",
    data: { ...ProCategoryStep.energy }
  };
  options[`monitoring-2`] = {
    title: "Dashboards",
    icon: "dashboards",
    data: { ...ProCategoryStep.dashboards }
  };

  // Platform
  options['platform-0'] = {
    title: "Mobile App",
    icon: "phone",
    data: { ...ProCategoryStep.mobileApp }
  };
  options['platform-1'] = {
    title: "Web App",
    icon: "webapp",
    data: { ...ProCategoryStep.webApp }
  };

  // Voice Assistants
  options[`voiceAssistants-0`] = {
    title: "Amazon Alexa",
    icon: "alexa",
    data: { ...ProCategoryStep.alexa }
  };
  options[`voiceAssistants-1`] = {
    title: "Google Assistant",
    icon: "google",
    data: { ...ProCategoryStep.google }
  };
  options[`voiceAssistants-2`] = {
    title: "Siri",
    icon: "siri",
    data: { ...ProCategoryStep.siri }
  };

  // Warranty
  options[`warranty-0`] = {
    title: "Defects",
    icon: "defects",
    data: homey.model === "homey5q" ? { ...ProCategoryStep.early2023.defects } : { ...ProCategoryStep.defects }
  };
  options[`warranty-1`] = {
    title: "Returns",
    icon: "returns",
    data: { ...ProCategoryStep.returns }
  };

  // Wireless Technologies
  options[`wireless-0`] = {
    title: "433 MHz",
    icon: "433mhz",
    data: { ...ProCategoryStep["433mhz"] }
  };
  if (homey.model !== "homey5q") {
    options[`wireless-1`] = {
      title: "868 MHz",
      icon: "868mhz",
      data: { ...ProCategoryStep["868mhz"] }
    };
  }
  options[`wireless-2`] = {
    title: "Bluetooth",
    icon: "bluetooth",
    data: { ...ProCategoryStep.bluetooth }
  };
  if (homey.model === "homey5q") {
    options[`wireless-3`] = {
      title: "Matter",
      icon: "matter",
      data: { ...ProCategoryStep.early2023.matter }
    };
  }
  options[`wireless-4`] = {
    title: "Infrared",
    icon: "infrared",
    data: { ...ProCategoryStep.infrared }
  };
  if (homey.model === "homey5q") {
    options[`wireless-5`] = {
      title: "Thread",
      icon: "thread",
      data: { ...ProCategoryStep.early2023.thread }
    };
  }
  options[`wireless-6`] = {
    title: "Wi-Fi",
    icon: "wifi",
    data: homey.model === "homey5q" ? { ...ProCategoryStep.early2023.wifi } : { ...ProCategoryStep.wifi }
  };
  options[`wireless-7`] = {
    title: "Zigbee",
    icon: "zigbee",
    data: homey.model === "homey5q" ? { ...ProCategoryStep.early2023.zigbee } : { ...ProCategoryStep.zigbee }
  };
  options[`wireless-8`] = {
    title: "Z-Wave",
    icon: "z-wave",
    data: homey.model === "homey5q" ? { ...ProCategoryStep.early2023.zwave } : { ...ProCategoryStep.zwave }
  };

  // More
  options[`more-0`] = {
    title: "Family",
    icon: "family",
    data: { ...ProCategoryStep.family }
  };
  if (homey.model === "homey5q") {
    options[`more-1`] = {
      title: "Ethernet Adapter",
      icon: "ethernet-adapter",
      data: { ...ProCategoryStep.ethernetAdapter }
    };
  }
  if (homey.model === "homey5q") {
    options[`more-2`] = {
      title: "Satellite Mode",
      icon: "satellite",
      data: { ...ProCategoryStep.satellite }
    };
  }
  options[`more-3`] = {
    title: "Backups",
    icon: "backups",
    data: { ...ProCategoryStep.backups }
  };
  options[`more-4`] = {
    title: "Other",
    icon: "other",
    data: homey.model === "homey5q" ? { ...ProCategoryStep.early2023.other } : { ...ProCategoryStep.other }
  };

  return (
      <>
        <div ref={stepRef} tabIndex={0} className="support-form-step">
          <StepHeader
              index={props.index}
              question="How can we help you with Homey Pro?"
              description="Choose the category that describes your question or problem best."
          >
            <div className={`support-form-step-header__warning ${updateAvailable && "is-visible"}`}>
              <div className="support-form-step-header__warning-icon"/>
              <div>
                <p className="support-form-step-header__warning-title">Homey Pro’s software is out of date.</p>
                <p className="support-form-step-header__warning-text">
                  Updating Homey Pro to the latest firmware version may resolve your issue.
                </p>
              </div>
            </div>
          </StepHeader>
          <div className="support-form-step__categories">
            <StepCategory heading="Apps & Devices">
              {Object.entries(options).map(([value, option]) => {
                return value.startsWith("appsAndDevices") && (
                    <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                      <SubCategoryTile title={option.title} icon={option.icon}/>
                    </TileCheckbox>
                )
              })}
            </StepCategory>
            <StepCategory heading="Automation">
              {Object.entries(options).map(([value, option]) => {
                return value.startsWith("automation") && (
                    <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                      <SubCategoryTile title={option.title} icon={option.icon}/>
                    </TileCheckbox>
                )
              })}
            </StepCategory>
            <StepCategory heading="Monitoring">
              {Object.entries(options).map(([value, option]) => {
                return value.startsWith("monitoring") && (
                    <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                      <SubCategoryTile title={option.title} icon={option.icon}/>
                    </TileCheckbox>
                )
              })}
            </StepCategory>
            <StepCategory heading="Platform">
              {Object.entries(options).map(([value, option]) => {
                return value.startsWith("platform") && (
                    <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                      <SubCategoryTile title={option.title} icon={option.icon}/>
                    </TileCheckbox>
                )
              })}
            </StepCategory>
            <StepCategory heading="Voice Assistants">
              {Object.entries(options).map(([value, option]) => {
                return value.startsWith("voiceAssistants") && (
                    <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                      <SubCategoryTile title={option.title} icon={option.icon}/>
                    </TileCheckbox>
                )
              })}
            </StepCategory>
            <StepCategory heading="Warranty">
              {Object.entries(options).map(([value, option]) => {
                return value.startsWith("warranty") && (
                    <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                      <SubCategoryTile title={option.title} icon={option.icon}/>
                    </TileCheckbox>
                )
              })}
            </StepCategory>
            <StepCategory heading="Wireless Technologies">
              {Object.entries(options).map(([value, option]) => {
                return value.startsWith("wireless") && (
                    <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                      <SubCategoryTile title={option.title} icon={option.icon}/>
                    </TileCheckbox>
                )
              })}
            </StepCategory>
            <StepCategory heading="More">
              {Object.entries(options).map(([value, option]) => {
                return value.startsWith("more") && (
                    <TileCheckbox key={value} name={name} value={value} check={checkValue} onChange={handleSelect}>
                      <SubCategoryTile title={option.title} icon={option.icon}/>
                    </TileCheckbox>
                )
              })}
            </StepCategory>
          </div>
        </div>
        {steps.length === props.index + 1 && (
            <div className="support-form-step">
              <StepHeader
                  index={props.index + 1}
                  question="Select one of the above options."
              />
            </div>
        )}
      </>
  );
}

ProCategoryStep.apps = {
  action: "apps",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["apps"],
  [dataKeys.path]: ["Apps"]
}
ProCategoryStep.devices = {
  action: "devices",
  [dataKeys.articles]: [4409624214034, 360025308654, 360012079234, 360026209853, 360012079134],
  [dataKeys.tags]: ["devices"],
  [dataKeys.path]: ["Devices"]
}
ProCategoryStep.flow = {
  action: "flow",
  [dataKeys.articles]: [360009669174, 360012168019, 360010575399, 360015464674, 4410240765586, 360025313254, 360012142153],
  [dataKeys.tags]: ["flow"],
  [dataKeys.path]: ["Flow"]
}
ProCategoryStep.advancedFlow = {
  action: "advancedFlow",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["advanced-flow"],
  [dataKeys.path]: ["Advanced Flow"]
}
ProCategoryStep.logic = {
  action: "logic",
  [dataKeys.articles]: [4410240765586, 18292876827548],
  [dataKeys.tags]: ["logic"],
  [dataKeys.path]: ["Logic"]
}
ProCategoryStep.insights = {
  action: "insights",
  [dataKeys.articles]: [360015370454, 4409687231890],
  [dataKeys.tags]: ["insights"],
  [dataKeys.path]: ["Insights"]
}
ProCategoryStep.energy = {
  action: "energy",
  [dataKeys.articles]: [360010187820, 360010282619],
  [dataKeys.tags]: ["energy"],
  [dataKeys.path]: ["Energy"]
}
ProCategoryStep.dashboards = {
  action: "dashboards",
  [dataKeys.articles]: [16732145289116, 16502176331164],
  [dataKeys.tags]: ["dashboards"],
  [dataKeys.path]: ["Dashboards"]
}
ProCategoryStep.mobileApp = {
  action: "mobileApp",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["mobile-app"],
  [dataKeys.path]: ["Mobile App"]
}
ProCategoryStep.webApp = {
  action: "webApp",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["web-app"],
  [dataKeys.path]: ["Web App"]
}
ProCategoryStep.alexa = {
  action: "alexa",
  [dataKeys.articles]: [360020154553, 4409841950738, 4409837342354],
  [dataKeys.tags]: ["alexa"],
  [dataKeys.path]: ["Amazon Alexa"]
}
ProCategoryStep.google = {
  action: "google",
  [dataKeys.articles]: [360022832954, 4409850306450, 4409858015250],
  [dataKeys.tags]: ["google-assistant"],
  [dataKeys.path]: ["Google Assistant"]
}
ProCategoryStep.siri = {
  action: "siri",
  [dataKeys.articles]: [4409843046290],
  [dataKeys.tags]: ["siri"],
  [dataKeys.path]: ["Siri"]
}
ProCategoryStep.defects = {
  action: "defects",
  [dataKeys.articles]: [360024602314, 360012077534],
  [dataKeys.tags]: ["defects"],
  [dataKeys.path]: ["Defects"]
}
ProCategoryStep.returns = {
  action: "returns",
  [dataKeys.articles]: [],
  [dataKeys.tags]: ["returns"],
  [dataKeys.path]: ["Returns"]
}
ProCategoryStep["433mhz"] = {
  action: "433mhz",
  [dataKeys.articles]: [4409763123602, 360020540614],
  [dataKeys.tags]: ["433mhz"],
  [dataKeys.path]: ["433 MHz"]
}
ProCategoryStep["868mhz"] = {
  action: "868mhz",
  [dataKeys.articles]: [4409763154322],
  [dataKeys.tags]: ["868mhz"],
  [dataKeys.path]: ["868 MHz"]
}
ProCategoryStep.bluetooth = {
  action: "bluetooth",
  [dataKeys.articles]: [4409762463122],
  [dataKeys.tags]: ["bluetooth"],
  [dataKeys.path]: ["Bluetooth"]
}
ProCategoryStep.infrared = {
  action: "infrared",
  [dataKeys.articles]: [4409790615698],
  [dataKeys.tags]: ["infrared"],
  [dataKeys.path]: ["Infrared"]
}
ProCategoryStep.wifi = {
  action: "wifi",
  [dataKeys.articles]: [4409776821010, 360012077154],
  [dataKeys.tags]: ["wifi"],
  [dataKeys.path]: ["Wi-Fi"]
}
ProCategoryStep.zigbee = {
  action: "zigbee",
  [dataKeys.articles]: [4409769766418, 360019240259, 360015669840, 360019239879],
  [dataKeys.tags]: ["zigbee"],
  [dataKeys.path]: ["Zigbee"]
}
ProCategoryStep.zwave = {
  action: "zwave",
  [dataKeys.articles]: [4409762628114, 360012182213, 4410500384274],
  [dataKeys.tags]: ["z-wave"],
  [dataKeys.path]: ["Z-Wave"]
}
ProCategoryStep.family = {
  action: "family",
  [dataKeys.articles]: [360012615814, 18186396006428, 360012141853, 360024746753],
  [dataKeys.tags]: ["family"],
  [dataKeys.path]: ["Family"]
}
ProCategoryStep.ethernetAdapter = {
  action: "ethernetAdapter",
  [dataKeys.articles]: [7262533910300, 8190402165788],
  [dataKeys.tags]: ["ethernet-adapter"],
  [dataKeys.path]: ["Ethernet Adapter"]
}
ProCategoryStep.satellite = {
  action: "satellite",
  [dataKeys.articles]: [7263667137052],
  [dataKeys.tags]: ["satellite-mode"],
  [dataKeys.path]: ["Satellite Mode"]
}
ProCategoryStep.backups = {
  action: "backups",
  [dataKeys.articles]: [360010668340, 4410239526290, 360011315299, 9302284997660],
  [dataKeys.tags]: ["backups"],
  [dataKeys.path]: ["Backups"]
}
ProCategoryStep.other = {
  action: "other",
  [dataKeys.articles]: [360009618514, 360017035053, 360012036674, 360012077014, 360024602314, 360012077534, 360012579034, 360013177034, 360015685913, 360015447093, 360013392813, 360024237434, 360024402693, 360024401113],
  [dataKeys.tags]: ["other"],
  [dataKeys.path]: ["Other"]
}

ProCategoryStep.early2023 = {
  defects: {
    action: "defects",
    [dataKeys.articles]: [7261843930396],
    [dataKeys.tags]: ["defects"],
    [dataKeys.path]: ["Defects"]
  },
  matter: {
    action: "matter",
    [dataKeys.articles]: [12934291420060, 9075551662108, 12010766903580],
    [dataKeys.tags]: ["matter"],
    [dataKeys.path]: ["Matter"]
  },
  thread: {
    action: "thread",
    [dataKeys.articles]: [12934291420060, 9075551662108, 12010766903580],
    [dataKeys.tags]: ["thread"],
    [dataKeys.path]: ["Thread"]
  },
  wifi: {
    action: "wifi",
    [dataKeys.articles]: [4409776821010, 7263241090716],
    [dataKeys.tags]: ["wifi"],
    [dataKeys.path]: ["Wi-Fi"]
  },
  zigbee: {
    action: "zigbee",
    [dataKeys.articles]: [10374465562652, 4409769766418, 360019240259, 360015669840],
    [dataKeys.tags]: ["zigbee"],
    [dataKeys.path]: ["Zigbee"]
  },
  zwave: {
    action: "zwave",
    [dataKeys.articles]: [10225734620188, 4409762628114, 360012182213, 4410500384274],
    [dataKeys.tags]: ["z-wave"],
    [dataKeys.path]: ["Z-Wave"]
  },
  other: {
    action: "other",
    [dataKeys.articles]: [7263241090716, 7262272408732, 9188099731228, 7261843930396, 7257933460764, 7263435817116, 8178797067292, 360013392813, 360024237434, 360024402693, 360024401113, 6673376100508],
    [dataKeys.tags]: ["other"],
    [dataKeys.path]: ["Other"]
  }
}

